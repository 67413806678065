import store from '../../store'

const getCurrentUser = () => {
  return store.dispatch('getCurrentUser')
}

export const requiresCompanyAuthentication = (to, from, next) => {
  if (!to.meta.requiresAuth) {
    next()
    return
  }

  if (!store.getters.isAuthenticated) {
    if (!store.getters.hasTokens) {
      next('/admin/login')
    } else {
      getCurrentUser()
        .then(() => {
          if (store.getters.isCompanyAdmin) {
            next()
          } else {
            next('/')
          }
        })
        .catch(() => {
          store.dispatch('logout', '/admin/login')
        })
    }
  } else {
    next()
  }
}

export const requiresCandidateAuthentication = (to, from, next) => {
  if (!to.meta.requiresAuth) {
    next()
    return
  }

  if (!store.getters.isAuthenticated) {
    if (!store.getters.hasTokens) {
      next('/accounts/login')
    } else {
      getCurrentUser()
        .then(() => {
          if (store.getters.isCompanyAdmin) {
            next('/admin')
          } else {
            next()
          }
        })
        .catch(() => {
          store.dispatch('logout', '/accounts/login')
        })
    }
  } else {
    next()
  }
}

export const redirectWhenAlreadyAuthenticated = (redirectTo) => {
  return (to, from, next) => {
    if (store.getters.hasTokens) {
      next(redirectTo)
    } else {
      next()
    }
  }
}
