const state = () => ({
  tokens: {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
  },
})

const getters = {
  token: (state) => `Bearer ${state.tokens.access}`,
  accessToken: (state) => state.tokens.access,
  refreshToken: (state) => state.tokens.refresh,
  hasTokens: (state, { accessToken, refreshToken }) => {
    return !!accessToken && !!refreshToken
  },
  isAuthenticated: (state, { currentUser }) => !!currentUser,
}

// actions
const actions = {
  async logout({ commit }, nextUrl) {
    commit('setLoading', true, { root: true })
    await commit('setTokens', {})
    sessionStorage.removeItem('editableTemporaryCurriculum')
    sessionStorage.removeItem('lastStep')
    commit('setLoading', false, { root: true })
    location.href = nextUrl
  },
  login({ commit }, { username, email, password }) {
    const credentials = { username, email, password }

    return this._vm.$axios.post('/auth/token', credentials).then((response) => {
      commit('setTokens', response.data)
      return Promise.resolve(response.data)
    })
  },
  updateAcessToken({ commit, getters }) {
    const payload = {
      refresh: getters.refreshToken,
    }
    return this._vm.$axios
      .post('/auth/token/refresh', payload)
      .then((response) => {
        const { access } = response.data
        commit('setTokens', { access, ...payload })
        return Promise.resolve(response.data)
      })
  },
}

// mutations
const mutations = {
  setTokens(state, { access, refresh }) {
    state.tokens = { access, refresh }

    if (access && refresh) {
      localStorage.setItem('access', access)
      localStorage.setItem('refresh', refresh)
    } else {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
