const state = () => ({
  states: [],
  cities: [],
})

const getters = {
  states: (state) => state.states,
  cities: (state) => state.cities,
}

const actions = {
  getCities({ commit }, params) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/location/cities', { params, headers })
      .then((response) => {
        commit('setCities', response.data)
        return Promise.resolve(response.data)
      })
  },
  getStates({ commit }, params) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/location/states', { params, headers })
      .then((response) => {
        commit('setStates', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setCities(state, cities) {
    state.cities = cities
  },
  setStates(state, states) {
    state.states = states
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
