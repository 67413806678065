'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    const { baseURL } = error.config
    let path = error.config.url.replace(baseURL, '')

    if (error.response.status == 401 && path !== '/auth/token/refresh') {
      const { code } = error.response.data || {}

      if (code == 'token_not_valid') {
        return store.dispatch('updateAcessToken').then(() => {
          error.config.headers['Authorization'] =
            'Bearer ' + store.getters.accessToken
          return _axios.request(error.config)
        })
      }
    }

    if (error.response.status === 503) {
      router.push('/503')
    }

    // Do something with response error
    return Promise.reject(error)
  }
)

// eslint-disable-next-line no-unused-vars
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
