import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import location from './modules/location'
import candidate from './modules/candidate/candidate'
import admin_selective_process from './modules/admin/selective_process'
import candidate_selective_process from './modules/candidate/selective_process'
import companies from './modules/admin/companies'
import questionnaires from './modules/admin/questionnaires'
import justifications from './modules/admin/justifications'
import recruiter_interview from './modules/admin/recruiter_interview'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      show: false,
      color: 'success',
      message: '',
      timeout: 3000,
    },
    overlay: false,
    cookies: localStorage.getItem('cookies')
      ? JSON.parse(localStorage.getItem('cookies'))
      : true,
    showHideCookie: true,
    loading: false,
  },
  getters: {
    showSnackbar: (state) =>
      state.snackbar.show && state.snackbar.message !== '',
    snackbarColor: (state) => state.snackbar.color,
    snackbarMessage: (state) => state.snackbar.message,
    showOverlay: (state) => state.overlay || state.snackbar.show,
    showCookies: (state) =>
      state.cookies === true && state.showHideCookie === true,
    loading: (state) => state.loading,
    logo: () => require('@/assets/logo.png'),
    snackbarAfterLogin: () => localStorage.getItem('showSnackbarAtBegin'),
    snackbarTimeout: (state) => state.snackbar.timeout,
  },
  mutations: {
    setError(state, message) {
      state.snackbar.messages.push(message)
      state.snackbar.color = 'error'
      state.snackbar.show = true
    },
    setSnackbarMessage(state, obj) {
      state.snackbar.color = obj.color
      state.snackbar.message = obj.message
      state.snackbar.logoutAfter = obj.logout || false
      state.snackbar.show = true
      state.snackbar.timeout = obj.timeout || 3000
    },
    resetSnackbar(state) {
      state.snackbar.show = false
      state.snackbar.message = ''
      state.snackbar.logoutAfter = false
      state.snackbar.color = 'success'
      state.snackbar.timeout = 3000
    },
    minimizeCookie(state) {
      state.showHideCookie = false
    },
    setLoading(state, status) {
      state.loading = status
    },
    setSnackbarAfter(state, snackbar_info) {
      localStorage.setItem('showSnackbarAtBegin', JSON.stringify(snackbar_info))
    },
  },
  actions: {},
  modules: {
    auth,
    user,
    location,
    candidate,
    admin_selective_process,
    candidate_selective_process,
    companies,
    questionnaires,
    justifications,
    recruiter_interview,
  },
})
