const state = () => ({
  specific_areas: [],
  general_areas: [],
  candidates: [],
  candidatesTotal: 0,
  generalAreasNoPaginated: [],
  specificAreasNoPaginated: [],
})

const getters = {
  specificAreasObject: (state) => state.specific_areas,
  specificAreas: (state) => state.specific_areas.results,
  totalSpecificAreas: (state) => state.specific_areas.total_items,
  generalAreas: (state) => state.general_areas.results,
  totalGeneralAreas: (state) => state.general_areas.total_items,
  generalAreasId: (state) => state.general_areas.map((area) => area.id),
  candidates: (state) => state.candidates,
  candidatesTotal: (state) => state.candidatesTotal,
  generalAreasNoPaginated: (state) => state.generalAreasNoPaginated,
  specificAreasNoPaginated: (state) => state.specificAreasNoPaginated,
}

const actions = {
  getCandidates({ commit }, filters) {
    const headers = {
      Authorization: this._vm.token,
    }
    const params = {
      ...filters,
    }
    return this._vm.$axios
      .get('/candidates', { headers, params })
      .then(({ data }) => {
        commit('setCandidates', data)
      })
  },

  async getGeneralAreas({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/expertises/general-area', { params, headers })
      .then(({ data }) => {
        commit('setGeneralAreas', data)
        return Promise.resolve(data)
      })
  },

  async getSpecificAreas(
    { commit },

    { id_general_area = undefined, params = {} }
  ) {
    const headers = {
      Authorization: this._vm.token,
    }

    const url = id_general_area
      ? `/expertises/specific-area?area=${id_general_area}`
      : '/expertises/specific-area'

    return this._vm.$axios.get(url, { params, headers }).then(({ data }) => {
      commit('setSpecificAreas', data)
      return Promise.resolve(data)
    })
  },

  getGeneralAreasNoPaginated({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/expertises/general-area/?no_paginate=true', { headers })
      .then((response) => {
        commit('setGeneralAreasNoPaginated', response.data)
        return Promise.resolve(response.data)
      })
  },

  async getSpecificAreasNoPaginated(
    { commit },
    { id_general_area = undefined, params = {} }
  ) {
    const headers = {
      Authorization: this._vm.token,
    }

    const url = id_general_area
      ? `/expertises/specific-area?area=${id_general_area}&no_paginate=true`
      : '/expertises/specific-area?no_paginate=true'

    return this._vm.$axios.get(url, { params, headers }).then(({ data }) => {
      commit('setSpecificAreasNoPaginated', data)
      return Promise.resolve(data)
    })
  },

  getSearchCandidates({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/candidates/?search=${params}`, { params, headers })
      .then((response) => {
        commit('setCandidates', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchGeneralAreas({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/expertises/general-area/?search=${params}`, { params, headers })
      .then((response) => {
        commit('setGeneralAreas', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchSpecificAreas({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/expertises/specific-area/?search=${params}`, { headers })
      .then((response) => {
        commit('setSpecificAreas', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setSpecificAreas(state, value) {
    state.specific_areas = value
  },
  setGeneralAreas(state, value) {
    state.general_areas = value
  },
  setCandidates(state, candidates) {
    state.candidates = candidates.results
    state.candidatesTotal = candidates.count
  },
  setGeneralAreasNoPaginated(state, value) {
    state.generalAreasNoPaginated = value
  },
  setSpecificAreasNoPaginated(state, value) {
    state.specificAreasNoPaginated = value
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
