import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import router from '@/router/index'
import pt from 'vuetify/es5/locale/pt'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, { iconfont: 'mdi' })

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: router.currentRoute.meta.isAdmin ? '#195190' : '#37778A',
        accent: '#E8BA3B',
        neutral: '#F4F6F9',
        bgButton: '#E0E0E0',
        btnTextColor: '#63666F',
        error: '#E53935',
        success: '#43A047',
        background: '#F4F6F9',
        secondaryBackground: '#ffffff',
        text: '#383D4B',
        primaryAdmin: '#195190',
      },
      dark: {
        primary: '#37778A',
        accent: '#E8BA3B',
        neutral: '#2C2F36',
        bgButton: '#E0E0E0',
        btnTextColor: '#63666F',
        error: '#E53935',
        success: '#43A047',
        background: '#060818',
        secondaryBackground: '#131B2D',
        text: '#ffffff',
        primaryAdmin: '#6CAFFB',
        grayText: '#A5A5A5',
        cardBackground: '#242A34',
        wineTag: '#3B2C34',
        ratingTag: '#1D2026',
      },
    },
  },
})
