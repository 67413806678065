import store from '../../store'
import {
  redirectWhenAlreadyAuthenticated,
  requiresCandidateAuthentication,
} from '../guards/auth'

export const candidateRoutes = [
  {
    path: '',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/layouts/candidate/Dashboard.vue'
      ),
    beforeEnter: requiresCandidateAuthentication,
    children: [
      {
        path: '',
        name: 'HomeCandidate',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/components/home/Candidate.vue'
          ),
        meta: {
          title: 'Página inicial',
          subtitle: 'Descrição da página inicial',
          requiresAuth: true,
        },
      },
      {
        path: 'notifications',
        name: 'Notificacoes',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/Notifications.vue'
          ),
        meta: {
          title: 'Notificações',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/register-confirmation',
        name: 'SuccessfulRegistration',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/general/SuccessfulRegistration.vue'
          ),
        meta: {
          title: 'Cadastro realizado',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/register-resume',
        name: 'Resume',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/Resume.vue'
          ),
        meta: {
          title: 'Preencher curriculo',
          requiresAuth: true,
        },
        beforeEnter: async (to, from, next) => {
          if (isObjectEmpty(store.getters.userCurriculum)) {
            await store.dispatch('getUserCurriculum')
          }

          if (store.getters.hasCompletedCurriculum) {
            next('/accounts/resume/my')
          } else {
            next()
          }
        },
      },
      {
        path: 'accounts/resume-confirmation',
        name: 'SuccessfulResumeSubmit',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/general/SuccessfulResumeSubmit.vue'
          ),
        meta: {
          title: 'Curriculo registrado',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/applied',
        name: 'JobsApplied',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/JobsApplied.vue'
          ),
        meta: {
          title: 'Minhas vagas',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/:id/details',
        name: 'JobDetails',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/JobDetails.vue'
          ),
        meta: {
          title: 'Detalhes da vaga',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/unsubscribe',
        name: 'JobUnsubscribe',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/JobUnsubscribe.vue'
          ),
        meta: {
          title: 'Desistência da vaga',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/:id/submited',
        name: 'JobSubmited',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/JobSubmited.vue'
          ),
        meta: {
          title: 'Cadastro na vaga',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/:id/result',
        name: 'Results',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/FollowMyCandidacy.vue'
          ),
        meta: {
          title: 'Resultado',
          requiresAuth: true,
        },
      },
      {
        path: 'jobs/:id/technical-test/instructions',
        name: 'TechnicalTestInstructions',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/technical_test/TechnicalTestInstructions.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'accounts/my',
        name: 'MyRegister',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/MyRegister.vue'
          ),
        meta: {
          title: 'Meu cadastro',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/registration/delete',
        name: 'RegistrationExclusion',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/general/RegistrationExclusion.vue'
          ),
        meta: {
          title: 'Exclusão de cadastro',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/resume/my',
        name: 'MyResume',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/MyResume.vue'
          ),
        meta: {
          title: 'Meu curriculo',
          requiresAuth: true,
        },
        beforeEnter: async (to, from, next) => {
          if (isObjectEmpty(store.getters.userCurriculum)) {
            await store.dispatch('getUserCurriculum')
          }
          if (!store.getters.hasCompletedCurriculum) {
            next('/accounts/register-resume')
          } else {
            next()
          }
        },
      },
      {
        path: 'accounts/behavioral-profile',
        name: 'BehaviourProfile',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/BehaviourProfile.vue'
          ),
        meta: {
          title: 'Perfil comportamental',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/frequently-asked',
        name: 'FrequentlyAsked',
        component: () =>
          import(
            /* webpackChunkName: "group-general" */ '@/views/candidate/general/FrequentlyAskedQuestions.vue'
          ),
        meta: {
          title: 'Perguntas frequentes',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/terms-of-use',
        name: 'TermsOfUse',
        component: () =>
          import(
            /* webpackChunkName: "group-general" */ '@/views/candidate/general/TermsOfUse.vue'
          ),
        meta: {
          title: 'Termos de uso',
          requiresAuth: true,
        },
      },
      {
        path: 'accounts/privacy-policy',
        name: 'PrivacyPolicy',
        component: () =>
          import(
            /* webpackChunkName: "group-general" */ '@/views/candidate/general/PrivacyPolicy.vue'
          ),
        meta: {
          title: 'Política de privacidade',
          requiresAuth: true,
        },
      },
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/layouts/candidate/TechnicalTestResolver.vue'
          ),
        props: true,
        beforeEnter: requiresCandidateAuthentication,
        children: [
          {
            path: 'jobs/:id/technical-test/in-progress',
            name: 'TechnicalTestInProgress',
            component: () =>
              import(
                /* webpackChunkName: "group-candidates" */ '@/views/candidate/jobs/technical_test/TechnicalTestProcess.vue'
              ),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: 'accounts/login',
    name: 'UserLogin',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/views/candidate/auth/Login.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/'),
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: 'accounts/register',
    name: 'Register',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/Register.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/'),
    meta: {
      title: 'Cadastro',
      requiresAuth: false,
    },
  },
  {
    path: 'accounts/password-reset',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/views/candidate/auth/ResetPassword.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/'),
    meta: {
      title: 'Recuperar senha',
      requiresAuth: false,
    },
  },
  {
    path: 'accounts/password-reset/token',
    name: 'ResetPasswordToken',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/views/candidate/auth/InsertTokenToResetPassword.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/'),
    meta: {
      title: 'Inserir token de recuperação de senha',
      requiresAuth: false,
    },
  },
  {
    path: 'accounts/password-reset/send-email',
    name: 'SendEmailResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "group-candidates" */ '@/views/candidate/auth/SendEmailResetPassword.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/'),
    meta: {
      title: 'E-mail enviado para redefinição de senha',
      requiresAuth: false,
    },
  },
]

function isObjectEmpty(value) {
  return Object.keys(value).length === 0 && value.constructor === Object
}
