const state = () => ({
  justifications: [],
})

const getters = {
  justifications: (state) => state.justifications.results,
  totalJustifications: (state) => state.justifications.total_items,
}

const actions = {
  getJustifications({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/justifications/', { params, headers })
      .then((response) => {
        commit('setJustifications', response.data)
        return Promise.resolve(response.data)
      })
  },

  getJustificationById(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/justifications/${id}`, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  createJustification({ commit }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .post('/justifications/', payload, { headers })
      .then((response) => {
        commit(
          'setSnackbarMessage',
          {
            message: 'A justificativa foi salva com sucesso.',
            color: 'success',
          },
          { root: true }
        )
        return Promise.resolve(response)
      })
  },

  editJustification({ commit }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .put(`/justifications/${payload.id}`, payload, { headers })
      .then((response) => {
        commit(
          'setSnackbarMessage',
          {
            message: 'Detalhes da justificativa salvos com sucesso.',
            color: 'success',
          },
          { root: true }
        )
        return Promise.resolve(response.data)
      })
  },

  deleteJustification(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.delete(`/justifications/${id}`, { headers })
  },

  getSearchJustifications({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    if (params.sort_by == '') delete params.sort_by

    return this._vm.$axios
      .get('/justifications/', {
        params,
        headers,
      })
      .then((response) => {
        commit('setJustifications', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setJustifications(state, justifications) {
    state.justifications = justifications
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
