<template>
  <v-dialog
    v-model="show"
    :dark="$vuetify.theme.dark"
    :light="!$vuetify.theme.dark"
    :hide-overlay="$vuetify.breakpoint.mdAndUp"
    origin="bottom"
    close-delay="3000"
    open-delay="0"
    :width="$vuetify.breakpoint.mobile ? 320 : 'auto'"
    max-width="500"
    :content-class="$vuetify.breakpoint.mobile ? '' : 'my-custom-dialog'"
  >
    <v-card height="auto" class="d-flex align-center pa-4 text-body-2 px-5">
      <v-icon
        v-text="getIconType"
        :color="snackbarColor"
        width="24"
        class="mr-3"
        left
      />
      <span class="font-weight-medium" v-html="message" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Snackbar',

  data() {
    return {}
  },

  watch: {
    show() {
      if (this.show) {
        setTimeout(() => (this.show = false), this.snackbarTimeout)
      } else {
        localStorage.removeItem('showSnackbarAtBegin')
      }
    },
  },

  computed: {
    ...mapGetters([
      'snackbarMessage',
      'snackbarColor',
      'showSnackbar',
      'snackbarTimeout',
    ]),

    show: {
      get() {
        return this.showSnackbar
      },
      set() {
        this.resetSnackbar()
      },
    },

    message() {
      return this.snackbarMessage
    },

    getIconType() {
      return this.snackbarColor === 'success'
        ? 'mdi-check-circle-outline'
        : 'mdi-close-circle-outline'
    },
  },

  methods: {
    ...mapMutations(['resetSnackbar']),
  },
}
</script>

<style scoped>
>>> .my-custom-dialog {
  align-self: flex-end;
}
</style>
