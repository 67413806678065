const state = () => ({
  companies: [],
  companiesNoPaginated: [],
  activeCompanies: [],
  inactiveCompanies: [],
})

const getters = {
  companies: (state) => state.companies.results,
  totalCompanies: (state) => state.companies.count,
  companiesNoPaginated: (state) => state.companiesNoPaginated,
  activeCompanies: (state) => state.activeCompanies.results,
  inactiveCompanies: (state) => state.inactiveCompanies.results,
  totalActiveCompanies: (state) => state.companies.count_active,
  totalInactiveCompanies: (state) => state.companies.count_inactive,
}

const actions = {
  getCompanies({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get('/companies', { headers }).then((response) => {
      commit('setCompanies', response.data)
      return Promise.resolve(response.data)
    })
  },

  getCompaniesNoPaginated({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/companies/?no_paginate=true', { headers })
      .then((response) => {
        commit('setCompaniesNoPaginated', response.data)
        return Promise.resolve(response.data)
      })
  },

  getActiveCompanies({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params.status = 'active'

    return this._vm.$axios
      .get('/companies/', { params, headers })
      .then((response) => {
        commit('setActiveCompanies', response.data)
        return Promise.resolve(response.data)
      })
  },

  getInactiveCompanies({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params.status = 'inactive'

    return this._vm.$axios
      .get('/companies/', { params, headers })
      .then((response) => {
        commit('setInactiveCompanies', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchCompanies({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/companies/?search=${params}`, { params, headers })
      .then((response) => {
        commit('setCompanies', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchActiveCompanies({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params = {
      ...params,
      status: 'active',
    }

    return this._vm.$axios
      .get('/companies/', { params, headers })
      .then((response) => {
        commit('setActiveCompanies', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchInactiveCompanies({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params = {
      ...params,
      status: 'inactive',
    }

    return this._vm.$axios
      .get('/companies/', { params, headers })
      .then((response) => {
        commit('setInactiveCompanies', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies
  },
  setCompaniesNoPaginated(state, companies) {
    state.companiesNoPaginated = companies.filter(
      (company) => company.is_active === true
    )
  },
  setActiveCompanies(state, companies) {
    state.activeCompanies = companies
  },
  setInactiveCompanies(state, companies) {
    state.inactiveCompanies = companies
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
