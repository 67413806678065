<template>
  <v-row justify="center" align="center" class="justify-center ma-0 mt-4 px-0">
    <v-col
      v-if="!hideCancel"
      cols="12"
      sm="4"
      xs="12"
      :order="$vuetify.breakpoint.xs ? 1 : 0"
    >
      <v-btn
        block
        outlined
        color="primary"
        class="mr-4 pr-4"
        :disabled="disableCancel"
        @click="$emit('cancel')"
        large
      >
        <v-icon v-text="cancelIcon" dense left />
        {{ cancelText }}
      </v-btn>
    </v-col>
    <v-col cols="12" sm="4" xs="12" :order="$vuetify.breakpoint.xs ? 0 : 1">
      <v-btn
        block
        color="primary"
        :disabled="disableAction"
        class="pr-6 pl-4"
        :loading="loading"
        large
        @click="$emit('action')"
      >
        <v-icon v-if="actionIcon" v-text="actionIcon" dense left />
        {{ actionText }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ActionButtons',

  props: {
    loading: { type: Boolean, default: false },
    actionText: { type: String, default: 'Salvar' },
    actionIcon: { type: String, default: 'mdi-content-save-outline' },
    disableAction: { type: Boolean, default: false },
    cancelText: { type: String, default: 'Cancelar' },
    cancelIcon: { type: String, default: 'mdi-close-circle' },
    disableCancel: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
  },
}
</script>
