import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vue-the-mask'
import './plugins/vuelidate'
import './plugins/vuetify-money'
import Snackbar from '@/components/general/Snackbar'
import ActionButtons from '@/components/general/ActionButtons'

Vue.config.productionTip = false
Vue.component('snackbar', Snackbar)
Vue.component('ActionButtons', ActionButtons)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
