const state = () => ({
  user: null,
  editableTemporaryCurriculum:
    JSON.parse(sessionStorage.getItem('editableTemporaryCurriculum')) || {},
  lastStep: sessionStorage.getItem('lastStep') || 1,
  userCurriculum: {},
  experiences: [],
  degree: [],
  languages: [],
  courses: [],
  users: [],
  activeUsers: [],
  inactiveUsers: [],
})

const getters = {
  isCompanyAdmin: (state) => state.user?.role !== 2,
  currentUser: (state) => state.user,
  userCurriculum: (state) => state.userCurriculum,
  editableTemporaryCurriculum: (state) =>
    state.editableTemporaryCurriculum || {},
  lastStep: (state) => state.lastStep,
  hasCompletedCurriculum: (state) => !!state.userCurriculum.id,
  experiences: (state) => state.experiences,
  degree: (state) => state.degree,
  languages: (state) => state.languages,
  courses: (state) => state.courses,
  users: (state) => state.users.results,
  totalUsers: (state) => state.users.count,
  totalActiveUsers: (state) => state.users.count_active,
  totalInactiveUsers: (state) => state.users.count_inactive,
  role: (state) => state.user?.role,
  activeUsers: (state) => state.activeUsers.results,
  inactiveUsers: (state) => state.inactiveUsers.results,
}

const actions = {
  getUserInfo({ commit }, type) {
    let url = ''

    const headers = {
      Authorization: this._vm.token,
    }
    if (type === 'experience') {
      url = '/registration/professional-experience'
      return this._vm.$axios
        .get(url, { headers })
        .then(({ data }) => commit('setExperiences', data))
        .catch((error) => console.error('get(experience):', error))
    } else if (type === 'degree') {
      url = '/registration/academic-formation'
      return this._vm.$axios
        .get(url, { headers })
        .then(({ data }) => commit('setDegree', data))
        .catch((error) => console.error('get(degree):', error))
    } else if (type === 'language') {
      url = '/registration/language-proficiency'
      return this._vm.$axios
        .get(url, { headers })
        .then(({ data }) => commit('setLanguages', data))
        .catch((error) => console.error('get(languages):', error))
    } else if (type === 'course') {
      url = '/registration/other-courses'
      return this._vm.$axios
        .get(url, { headers })
        .then(({ data }) => commit('setCourses', data))
        .catch((error) => console.error('get(courses):', error))
    }
  },

  deleteUserInfoResume({ dispatch }, info) {
    let url = ''

    const { id, type } = info

    const headers = {
      Authorization: this._vm.token,
    }
    if (type === 'experience') {
      url = `/registration/professional-experience/${id}`
      return this._vm.$axios
        .delete(url, { headers })
        .then(() => dispatch('getUserInfo', type))
        .catch((error) => console.error('delete(experience):', error))
    } else if (type === 'degree') {
      url = `/registration/academic-formation/${id}`
      return this._vm.$axios
        .delete(url, { headers })
        .then(() => dispatch('getUserInfo', type))
        .catch((error) => console.error('delete(degree):', error))
    } else if (type === 'language') {
      url = `/registration/language-proficiency/${id}`
      return this._vm.$axios
        .delete(url, { headers })
        .then(() => dispatch('getUserInfo', type))
        .catch((error) => console.error('delete(languages):', error))
    } else if (type === 'course') {
      url = `/registration/other-courses/${id}`
      return this._vm.$axios
        .delete(url, { headers })
        .then(() => dispatch('getUserInfo', type))
        .catch((error) => console.error('delete(courses):', error))
    }
  },

  getCurrentUser({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get('/users/me', { headers }).then((response) => {
      commit('setUser', response.data)
      return Promise.resolve(response.data)
    })
  },

  patchCurrentUser({ commit }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .patch('/users/me', payload, { headers })
      .then((response) => {
        commit('setUser', response.data)
        return Promise.resolve(response.data)
      })
  },

  putCurrentUserPassword(context, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .patch('/users/me/password', payload, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getUserCurriculum({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/registration/candidate', { headers })
      .then((response) => {
        commit('setUserCurriculum', response.data)
        return Promise.resolve(response.data)
      })
  },

  setUserCurriculum({ commit }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .put('/registration/candidate', payload, { headers })
      .then((response) => {
        commit('setUserCurriculum', response.data)
        return Promise.resolve(response.data)
      })
  },

  getUsers({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/users/', { params, headers })
      .then((response) => {
        commit('setUsers', response.data)
        return Promise.resolve(response.data)
      })
  },

  getUsersActive({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params.status = 'active'

    return this._vm.$axios
      .get('/users/', { params, headers })
      .then((response) => {
        commit('setActiveUsers', response.data)
        return Promise.resolve(response.data)
      })
  },

  getUsersInactive({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params.status = 'inactive'

    return this._vm.$axios
      .get('/users/', { params, headers })
      .then((response) => {
        commit('setInactiveUsers', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchUsers({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/users/?search=${params}`, { params, headers })
      .then((response) => {
        commit('setUsers', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchActiveUsers({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params = {
      ...params,
      status: 'active',
    }

    return this._vm.$axios
      .get('/users/', { params, headers })
      .then((response) => {
        commit('setActiveUsers', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSearchInactiveUsers({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    params = {
      ...params,
      status: 'inactive',
    }

    return this._vm.$axios
      .get('/users/', { params, headers })
      .then((response) => {
        commit('setInactiveUsers', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setLastStep(state, step) {
    state.lastStep = step
    sessionStorage.setItem('lastStep', JSON.stringify(step))
  },
  setUser(state, user_info) {
    state.user = user_info
  },
  setUserCurriculum(state, user_curriculum) {
    state.userCurriculum = user_curriculum
  },
  setEditableTemporaryCurriculum(state, editableTemporaryCurriculum) {
    state.editableTemporaryCurriculum = editableTemporaryCurriculum
    sessionStorage.setItem(
      'editableTemporaryCurriculum',
      JSON.stringify(editableTemporaryCurriculum)
    )
  },
  setExperiences(state, experiences) {
    state.experiences = experiences
  },
  setDegree(state, degree) {
    state.degree = degree
  },
  setLanguages(state, languages) {
    state.languages = languages
  },
  setCourses(state, courses) {
    state.courses = courses
  },
  setUsers(state, users) {
    state.users = users
  },
  setActiveUsers(state, activeUsers) {
    state.activeUsers = activeUsers
  },
  setInactiveUsers(state, inactiveUsers) {
    state.inactiveUsers = inactiveUsers
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
